import { Component, OnInit } from '@angular/core';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { ActivatedRouteSnapshot } from '../../../node_modules/@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-nav-footer',
  templateUrl: './nav-footer.component.html',
  styleUrls: ['./nav-footer.component.css']
})
export class NavFooterComponent implements OnInit {
  public currentUser: string;
  public isLoggedIn: boolean = false;
  public loggedInObservable: Observable<boolean>;

  constructor(
    public authService: AuthService,
    public userService: UserService
  ) {
  }

  ngOnInit() {

      this.loggedInObservable = Observable.create(
          observer => this.userService.afAuth
            .onAuthStateChanged((user) => {
              this.isLoggedIn = !!user;
              if(user) {
                this.currentUser = user.email;
              } else {
                this.currentUser = 'Not logged in';
              }
              
              observer.next(!!user)
            })
        ).subscribe();

  }


}
