<div class="container">
  <div class="row w-100">
    <div class="col-md-8 image-border">
      <img class="thumbnail" src="assets/images/login2.png">
      <h1 class="my-4">Login with Firebase</h1>
    </div>
  </div>
  <div class="row w-100">
    <div class="col-md-8">
      <form [formGroup]="loginForm" *ngIf="!isLoggedIn">
        <div class="form-group">
          <label>Email address</label>
          <input type="email" name="email" formControlName="email" class="form-control" required>
        </div>

        <div class="form-group">
          <label>Password</label>
          <input type="password" class="form-control" formControlName="password" required>
          <label class="error">{{errorMessage}}</label>
        </div>
        <button type="submit" (click)="tryLogin(loginForm.value)"class="btn btn-outline-primary btn-space btn-sm">Login</button>
      </form>
    </div>
  </div>
  <div class="row w-100" *ngIf="!isLoggedIn">
    <div class="col-md-8">
      <p>No account yet?
        <a routerLink="/register" routerLinkActive="true">Create an account</a>
      </p>
    </div>
  </div>
  <div class="row w-100" *ngIf="isLoggedIn">
    <div class="col-md-8">
      <p>You are currently Logged in.  Do you want to log out?</p>
      <p><button (click)="logOut()"class="btn btn-outline-primary btn-space btn-sm">Logout</button></p>
    </div>
  </div>
</div>
