<div class="container h-container">
  <div class="row w-100">
    <div class="col-md-12">
      <img class="thumbnail" src="assets/images/admin.png">
    </div>
  </div>
  <div class="row w-100">
    <div class="col-md-4">
      <h1 class="my-4">Topics</h1>
      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title" *ngIf='topicCount'>{{topicCount}}</h2>
          <mat-spinner color="accent" *ngIf='!topicCount'></mat-spinner>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h1 class="my-4">Resources</h1>
      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title" *ngIf='linkCount'>{{linkCount}}</h2>
          <mat-spinner color="accent" *ngIf='!linkCount'></mat-spinner>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h1 class="my-4">Tags</h1>
      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title" *ngIf='tagCount'>{{tagCount}}</h2>
          <mat-spinner color="accent" *ngIf='!tagCount'></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
