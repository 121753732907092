<!-- Blog Entries Column -->
<div class="container">
    <div class="row row-centered" *ngIf='hideElement'>
      <div class="col-md-12 col-centered my-spinner">
        <mat-spinner color="accent"></mat-spinner>
      </div>
    </div>
    <div class="row" *ngIf='!hideElement'>
      <div class="col-md-8" *ngIf="!hideElement">
        <h1 class="my-4">Games
        </h1>
  
        <!-- Blog Post -->
        <div class="card mb-4">
          <img class="card-img-top" src="assets/images/stickfigure.jpg" alt="Card image cap">
          <div class="card-body">
            <h2 class="card-title">Pictionary Game</h2>
            <p class="card-text">Game description</p>
            <a [routerLink]="['/games/draw/action','draw']" routerLinkActive="true" class="btn btn-primary">Draw</a>
            &nbsp;
            <a [routerLink]="['/games/draw/action','guess']" routerLinkActive="true" class="btn btn-primary">Guess</a>
          </div>
        </div>
  
  
      </div>
  
      
    </div>
  </div>
  