<!-- Blog Entries Column -->
<div class="container">
  <div class="row row-centered" *ngIf='hideElement'>
    <div class="col-md-12 col-centered my-spinner">
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
  <div class="row" *ngIf='!hideElement'>
    <div class="col-md-8" *ngIf="!hideElement">
      <h1 class="my-4">Welcome to our site</h1>

      <!-- Blog Post -->
      <div class="card mb-4" *ngFor='let topic of allTopics'>
        <img class="card-img-top" src="assets/images/{{topic.image | lowercase}}" alt="Card image cap">
        <div class="card-body">
          <h2 class="card-title">{{topic.title}}</h2>
          <p class="card-text">{{topic.description}}</p>
          <a [routerLink]="['/detail/item',topic.homekey]" routerLinkActive="true" class="btn btn-primary">Read More &rarr;</a>
        </div>
      </div>


    </div>

    <div class="col-md-4">
      <app-nav-right></app-nav-right>
    </div>
  </div>
</div>
