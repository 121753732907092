<!-- Blog Entries Column -->
<div width="100%" class="noscroll nohighlight">
    <div class="nohighlight">
        <canvas #canvas width="500" height="175" class="canvas"></canvas>
    </div>
    <div class="card" *ngIf="action === 'draw'">
        <div class="card-body word">
            Your Word is: {{guessWord}} 
        </div>
    </div>
    
    <div class="drw-space">
        <button class="btn btn-primary clear-btn" *ngIf="action === 'draw'" mat-button (click)="clear()">Clear</button>
    </div>
    <!-- <div>
        <p>{{ coordinates }}</p>
        <p>{{ testVal }}</p>
    </div> -->
    
</div>
<!-- <p>{{selectedGame | async | json}}</p> -->