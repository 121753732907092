import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
// import * as firebase from 'firebase/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


import { Link } from '../models/link';
import { BookmarkFolder } from '../models/bookmarks';
import { Topic } from '../models/topic';
import { Tag } from '../models/tag';

@Injectable()
export class DataService {

  constructor(private http: HttpClient,
    public afAuth: AngularFireAuth) { }

  getAllTopics(): Observable<Link[]> {
    console.log('Getting all topics from firebase.');
    return this.http.get<Link[]>('https://onezandzeros-a1596.firebaseio.com/home.json');
  }

  getAllLinks(): Observable<Link[]> {
    console.log('Getting all topics from firebase.');
    return this.http.get<Link[]>('https://onezandzeros-a1596.firebaseio.com/links.json');
  }

  getAllBookmarksObs(): Observable<BookmarkFolder[]> {
    console.log('Getting all topics from firebase.');
    return this.http.get<BookmarkFolder[]>('https://onezandzeros-a1596.firebaseio.com/bookmark_folders.json');
  }


  getAllBookmarks(): Promise<BookmarkFolder[]> {
    console.log('Getting all topics from firebase.');
    return this.http.get<BookmarkFolder[]>('https://onezandzeros-a1596.firebaseio.com/bookmark_folders.json').toPromise();
  }



  updateBookMarkFolder(bookmarkFolder: BookmarkFolder): Promise<any> {
    // return new Promise<any>((resolve, reject) => {
      return  firebase.auth().currentUser.getIdToken()
        .then(authToken => {
          const params = new HttpParams()
            .set('auth', `${authToken}`);
          return this.http.put(`https://onezandzeros-a1596.firebaseio.com/bookmark_folders/${bookmarkFolder._sysKey}.json`,
            bookmarkFolder, {
              headers: new HttpHeaders({
                'Authorization': 'Bearer ' + authToken,
                'Content-Type': 'application/json'
              }), params
            }).toPromise();
        });
    // });
  }

  getAllTags(): Observable<Tag[]> {
    console.log('Getting tags firebase.');
    return this.http.get<Tag[]>('https://onezandzeros-a1596.firebaseio.com/tags.json');
  }

  getinksByTag(tag: string): Observable<Link[]> {
    console.log('Getting all links by topic from firebase.');
    const params = new HttpParams()
      .set('orderBy', '"tag"')
      .set('equalTo', '"' + tag + '"');
    return this.http.get<Link[]>('https://onezandzeros-a1596.firebaseio.com/links.json', { params });
  }

  getLinkById(id: any): Observable<Link> {
    console.log('Getting one topic from firebase.');
    return this.http.get<Link>('https://onezandzeros-a1596.firebaseio.com/home/' + id + '.json');
  }

  deleteLinkById(_sysKey: string): Promise<any> {
    return  firebase.auth().currentUser.getIdToken()
    .then(authToken => {
      const params = new HttpParams()
        .set('auth', `${authToken}`);
      return this.http.delete(`https://onezandzeros-a1596.firebaseio.com/links/${_sysKey}.json`,
         {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + authToken,
            'Content-Type': 'application/json'
          }), params
        }).toPromise();
    });
  }

  updateLink(updatedLink: Link): Promise<any> {
    // return new Promise<any>((resolve, reject) => {
    return  firebase.auth().currentUser.getIdToken()
        .then(authToken => {
          const params = new HttpParams()
            .set('auth', `${authToken}`);
          return this.http.put(`https://onezandzeros-a1596.firebaseio.com/links/${updatedLink._sysKey}.json`,
            updatedLink, {
              headers: new HttpHeaders({
                'Authorization': 'Bearer ' + authToken,
                'Content-Type': 'application/json'
              }), params
            }).toPromise();
        });
    // });
  }

  addLink(addLink: Link): Promise<any> {
    return  firebase.auth().currentUser.getIdToken()
        .then(authToken => {
          const params = new HttpParams()
            .set('auth', `${authToken}`);
          return this.http.post(`https://onezandzeros-a1596.firebaseio.com/links.json`,
              addLink, {
              headers: new HttpHeaders({
                'Authorization': 'Bearer ' + authToken,
                'Content-Type': 'application/json'
              }), params
            }).toPromise();
        });
  }


}
