import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireDatabase, AngularFireObject, AngularFireList } from '@angular/fire/compat/database';
import { Observable, from } from 'rxjs';
// import { map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { fromEvent } from 'rxjs';
import { map, merge, switchMap, takeUntil, pairwise } from 'rxjs/operators';

export class Game {
  id?: string;
  user: string;
  coordinates: string[];
}

@Component({
  selector: 'app-draw-game',
  templateUrl: './draw-game.component.html',
  styleUrls: ['./draw-game.component.css']
})
export class DrawGameComponent implements OnInit, AfterViewInit {

  @ViewChild('canvas') public canvas: ElementRef;

  @Input() public width = 400;
  @Input() public height = 400;

  public items: Observable<any[]>;
  private cx: CanvasRenderingContext2D;

  userCollection: AngularFirestoreCollection<any>;
  // public db: AngularFirestore;
  // public selectedGame: Observable<Game>;
  public testVal: String = 'start';
  public selectedGame: Observable<string[]>;
  public gameDoc: AngularFirestoreDocument<Game>;
  public userObject;
  public coordinates: string[] = [];
  public hideElement: Boolean = false;
  public action: String = 'guess';
  private root;
  private paramsSub: any;
  public itemRef: AngularFireObject<any>;
  public itemsRef: AngularFireList<any>;
  public guessWord: string;
  public DomRect: DOMRect;
  // public coordinates: string;
  // public itemsRef: AngularFireList<any>;
  public item: Observable<any>;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private db: AngularFireDatabase,
  ) {
    // this.itemRef = db.list('games/test').valueChanges();

  }

  public ngOnInit(): void {

    // this.root = this.db.database.ref();
    // this.root.on('value', function(snap) {
    //    console.log(snap.val());
    //  });

    this.paramsSub = this.route.firstChild.params.subscribe(params => {
      this.action = params['action'];
    });

    this.itemRef = this.db.object('games');
    this.itemsRef = this.db.list('games/picturewords');
    const randWord = Math.floor(Math.random() * 46) + 1;
    this.itemsRef.valueChanges().subscribe((items) => {
      this.guessWord = items[randWord];
    });
    // this.guessWord = this.itemsRef[randWord];
    // this.items = this.itemsRef.valueChanges();
    // this.items.subscribe(v => console.log(v));
    // this.coordinates = this.itemRef['coordinates'];
    this.itemRef.snapshotChanges().subscribe(action => {
      if (this.action === 'guess') {
        const val = action.payload.val();
        this.coordinates = val['coordinates'];
        this.draw();
      }
    });
    // this.item = this.itemRef.valueChanges();
    // this.item.subscribe(value => {
    //   if (this.action === 'guess') {
    //     this.draw();
    //   }
    // });
    // console.log(this.item);
    // this.item.snapshotChanges().subscribe(action => {
    // console.log(action.payl.val());
    //  console.log(action);
    // });
    // Use snapshotChanges().map() to store the key
    // this.links = this.linksRef.snapshotChanges().map(changes => {
    //   return changes.map(c => ({ key: c.payload.key, ...c.payload.val() })      );
    // });
    // console.log(this.links);
    // this.db.list('/links').valueChanges()   // returns observable
    //           .subscribe(list => {
    //           this.links = list;
    //           console.log(this.links);
    //        });

    // this.itemRef = this.db.object('/games/drawing');
    // let gameRef = this.db.list('/games');
    // console.log(gameRef);
    // this.itemRef.set({ name: 'new name!'});
    // this.itemRef.update({ item: 'this is a change' });
    // listRef.snapshotChanges().subscribe(action => {
    //  console.log(action);
    // console.log(action.type);
    // console.log(action.key);
    // console.log(action.payload.val());
    // });


    // //this.gameDoc = this.afs.doc('drawings/VZvtP5ZvttheYiSIEw2G');
    // this.selectedGame = this.gameDoc.valueChanges();
    // this.gameDoc.snapshotChanges()
    //   .pipe(
    //     map(({ payload }) => ({ ...payload.data(), id: payload.id }))
    //   )
    //   .subscribe(res => {
    //     if (this.action === 'guess') {
    //       this.coordinates = res.coordinates;
    //       this.draw();
    //     }
    //   });
  }

  public clear() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx.clearRect(0, 0, canvasEl.width, canvasEl.height);
    this.coordinates = [];
    this.updateFirestore();
  }

  public draw() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx.clearRect(0, 0, canvasEl.width, canvasEl.height);
    if (this.coordinates && this.coordinates.length) {
      for (let i = 0; i < this.coordinates.length; i++) {
        const coord = this.coordinates[i].split(',');
        const prevPos = {
          x: parseInt(coord[0], 0),
          y: parseInt(coord[1], 0)
        };

        const currentPos = {
          x: parseInt(coord[2], 0),
          y: parseInt(coord[3], 0)
        };

        this.reDrawOnCanvas(prevPos, currentPos);
      }
    }

    // this.coordinates = [];
    // this.updateFirestore();
  }

  public ngAfterViewInit() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.DomRect = canvasEl.getBoundingClientRect();
    this.cx = canvasEl.getContext('2d');
    canvasEl.width = this.width;
    canvasEl.height = this.height;

    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';
    if (this.action === 'draw') {
      this.captureEvents(canvasEl);
    }
    setTimeout(() => {
      this.DomRect = canvasEl.getBoundingClientRect();
    }, 1000);

  }


  private captureAllEvents(canvasEl: HTMLCanvasElement) {

    const mouseDown = fromEvent(canvasEl, 'mousedown')
    .pipe(merge(fromEvent(canvasEl, 'touchend'))
    );

    const mouseUp = fromEvent(canvasEl, 'mouseup')
    .pipe(merge(fromEvent(canvasEl, 'mouseleave')))
    .pipe(merge(fromEvent(canvasEl, 'touchend')))
    .pipe(merge(fromEvent(canvasEl, 'touchcancel'))
    );

    const mouseMove = fromEvent(canvasEl, 'mousemove')
      .pipe(merge(fromEvent(canvasEl, 'touchmove'))
    );




  }


  private captureEvents(canvasEl: HTMLCanvasElement) {


    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              pairwise() /* Return the previous and last values as array */
            );
        })
      ).subscribe((res: [MouseEvent, MouseEvent]) => {
        // const rect = canvasEl.getBoundingClientRect();
        const rect = this.DomRect;
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        this.drawOnCanvas(prevPos, currentPos);
      });

    fromEvent(canvasEl, 'touchstart')
      .pipe(
        switchMap((e) => {
          return fromEvent(canvasEl, 'touchmove')
            .pipe(
              takeUntil(fromEvent(canvasEl, 'touchend')),
              pairwise() /* Return the previous and last values as array */
            );
        })
      ).subscribe((res: [TouchEvent, TouchEvent]) => {
        if (res[0].target === canvasEl) {
          res[0].preventDefault();
        }
        // if (res[0].target === canvasEl) {
        // const rect = canvasEl.getBoundingClientRect();
        const rect = this.DomRect;
        const prevPos = {
          // x: res[0].targetTouches[0].clientX - rect.left,
          // y: res[0].targetTouches[0].clientY - rect.top
          // x: res[1].changedTouches[0].clientX - rect.left,
          // y: res[1].changedTouches[0].clientY - rect.top
          // x: res[0].changedTouches[0].pageX,
          // y: res[0].changedTouches[0].pageY

           x: res[1].touches[0].clientX - rect.left,
           y: res[1].touches[0].clientY - rect.top

          // x: res[1].touches[0].pageX - rect.left,
          // y: res[1].touches[0].pageY - rect.top


        };
        // const i = res[1].touches.length - 1;
        const currentPos = {
          // x: res[1].targetTouches[0].clientX - rect.left,
          // y: res[1].targetTouches[0].clientY - rect.top
          // x: res[1].changedTouches[0].pageX,
          // y: res[1].changedTouches[0].pageY

          x: res[0].changedTouches[0].clientX - rect.left,
          y: res[0].changedTouches[0].clientY - rect.top // - rect.top

          // x: res[0].changedTouches[0].pageX - rect.left,
          // y: res[0].changedTouches[0].pageY - rect.top


        };
        // this.touchOnCanvas(res[0].changedTouches[0].clientX, res[0].changedTouches[0].clientY);
        this.touchOnCanvas(prevPos, currentPos);
      });

  }
  // canvas.addEventListener('touchstart', engage, false);
  //  canvas.addEventListener('touchmove', putPoint, false);
  // canvas.addEventListener('touchend', disengage, false);

  private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {

    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;

    if (!this.cx) { return; }

    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
      const coord = prevPos.x.toString() + ',' + prevPos.y.toString() + ',' + currentPos.x.toString() + ',' + currentPos.y.toString();
      // this.selectedGame.pipe( this.coordinates );
      // this.testVal = prevPos.x.toString() + ' - ' + currentPos.x.toString();
      this.testVal = 'Mouse: ' + prevPos.y.toString() + ' - ' + currentPos.y.toString();
      this.coordinates.push(coord);
      this.updateFirestore();
    }
  }

  private touchOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
    if (!this.cx) { return; }

    if (prevPos) {
      this.cx.beginPath();
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
      const coord = prevPos.x.toString() + ',' + prevPos.y.toString() + ',' + currentPos.x.toString() + ',' + currentPos.y.toString();
      this.testVal = 'Touch: ' + prevPos.y.toString() + ' - ' + currentPos.y.toString();
      this.coordinates.push(coord);
      this.updateFirestore();
    }
  }

  private touchOnCanvasArc(x: number, y: number) {
    if (!this.cx) { return; }

    this.cx.beginPath();
    // this.cx.fillStyle = '#ff8330';
    this.cx.arc(x, y, 1, 0, 2 * Math.PI);
    this.cx.fill();
    this.cx.closePath();

    this.cx.beginPath();
    // const coord = x.toString() + ',' + y.toString() + ',' + currentPos.x.toString() + ',' + currentPos.y.toString();
    // this.coordinates.push(coord);
    // this.updateFirestore();
  }


  private reDrawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
    if (!this.cx) { return; }

    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }
  }

  public updateFirestore() {
    this.itemRef.update({ coordinates: this.coordinates });
  }

}
