import { Component, OnInit, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Link } from '../models/link';
import { DataService } from '../core/data.service';
import { ActivatedRoute, ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { switchMap, mapTo } from 'rxjs/operators';
import { SysKeysPipe } from '../syskeys.pipe';
import { NavigationService } from '../core/navigation.service';


@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
@Injectable()
export class LinksComponent implements OnInit {

  constructor(private dataService: DataService,
    private route: ActivatedRoute,
    public sysKeysPipe: SysKeysPipe,
    public navigationService: NavigationService
  ) {
    this.navigationService.currentTab = 'resources';
  }

  public allLinks: Link[];
  public hideElement: true;
  public tag: string;
  private paramsSub: any;


  ngOnInit() {

    this.paramsSub = this.route.firstChild.params.subscribe(params => {
      this.tag = params['tag'];
      console.log(this.tag);
    });
    if (this.tag) {
      this.dataService.getinksByTag(this.tag)
        .subscribe(
          (data: Link[]) => this.allLinks = this.sysKeysPipe.transform(data),
          (err: any) => console.log(err),
          () => console.log('All done getting links by tag.')
        );
    } else {
      this.dataService.getAllLinks()
        .subscribe(
          (data: Link[]) => this.allLinks = this.sysKeysPipe.transform(data),
          (err: any) => console.log(err),
          () => console.log('All done getting links.')
        );
    }
  }

}
