import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'syskeys'
})
export class SysKeysPipe implements PipeTransform {

  public transform(value: any, args?: any): any {
      const keys = [];
      for (const key in value) {
        if (key) {
          // keys.push({key: key, value: value[key]});
          const x: any = value[key];
          x._sysKey = key;
          // keys.push(value[key]);
          keys.push(x);
        }
      }
      return keys;
  }

}
