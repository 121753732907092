    <!-- Search Widget -->
    <div class="card my-4">
      <h5 class="card-header">Search</h5>
      <div class="card-body">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Search for...">
          <span class="input-group-btn">
            <button class="btn btn-secondary" type="button">Go!</button>
          </span>
        </div>
      </div>
    </div>

    <!-- Resources Widget -->
    <div class="card my-4">
      <h5 class="card-header">Resources</h5>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <ul class="list-unstyled mb-0">
              <li *ngFor='let tag of allTags'>
                <a [routerLink]="['/links/tag',tag.link | lowercase]" routerLinkActive="true">{{tag.name}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Side Widget -->
    <div class="card my-4">
      <h5 class="card-header">Technologies</h5>
      <div class="card-body">
        This site was built using the following technologies: {{techList}}
      </div>
    </div>

