import { Component, OnInit, Injectable } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { Link } from '../models/link';
import { DataService } from '../core/data.service';
import { ActivatedRoute, ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { SysKeysPipe } from '../syskeys.pipe';
import { Form } from '@angular/forms';
import { Tag } from '../models/tag';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  allTopics: Link[];
  hideElement: true;
  topicCount: Number;
  linkCount: Number;
  tagCount: Number;


  constructor(private dataService: DataService,
    private route: ActivatedRoute,
    public sysKeysPipe: SysKeysPipe,
    public authService: AuthService,
    public MatProgressSpinnerModule: MatProgressSpinnerModule
  ) { }
   ngOnInit() {

    this.dataService.getAllTopics()
      .subscribe(
        (data: Link[]) => this.topicCount = data.length,
        (err: any) => console.log(err),
        () => console.log('Topic count.')
      );

      this.dataService.getAllTags()
      .subscribe(
        (data: Tag[]) => this.tagCount = data.length,
        (err: any) => console.log(err),
        () => console.log('Tag count.')
      );

      this.dataService.getAllLinks()
      .subscribe(
        (data: Link[]) => this.linkCount = this.sysKeysPipe.transform(data).length,
        (err: any) => console.log(err),
        () => console.log('Link count.')
      );


  }



}
