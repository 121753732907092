import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { Router, Params } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../core/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  errorMessage: String = '';
  public isLoggedIn: Boolean = false;
  public loggedInObservable: Observable<boolean>;

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private userService: UserService,
  ) {
    this.createForm();
  }
  ngOnInit() {

    this.loggedInObservable = Observable.create(
      observer => this.userService.afAuth
        .onAuthStateChanged((user) => {
          this.isLoggedIn = !!user;
          observer.next(!!user)
        })
    ).subscribe();

    this.userService.getCurrentUser()
    .then(user => {
      console.log('Resolve True');
      if(user) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }

    }, err => {
      console.log('Resolve False');
    });



  }

  public logOut() {

    this.authService.isLoggedIn()
    .then(user => {
      console.log('Resolve True');
      this.authService.doLogout();
      this.isLoggedIn = false;
    }, err => {
      console.log(err);
      this.isLoggedIn = false;
    });

  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // tryFacebookLogin() {
  //   this.authService.doFacebookLogin()
  //     .then(res => {
  //       this.router.navigate(['/user']);
  //     });
  // }

  // tryTwitterLogin() {
  //   this.authService.doTwitterLogin()
  //     .then(res => {
  //       this.router.navigate(['/user']);
  //     });
  // }

  // tryGoogleLogin() {
  //   this.authService.doGoogleLogin()
  //     .then(res => {
  //       this.router.navigate(['/user']);
  //     });
  // }

  tryLogin(value) {
    if (this.loginForm.valid) {
      this.authService.doLogin(value)
        .then(res => {
          this.router.navigate(['/home']);
        }, err => {
          console.log(err);
          this.errorMessage = err.message;
        });
    } else {
      //this.errorMessage = this.loginForm.controls["email"].errors.required;
    }
  }
}

