import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { RegisterComponent } from './register/register.component';
import { UserResolver } from './user/user.resolver';
import { AuthGuard } from './core/auth.guard';
import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { NavTopComponent } from './nav-top/nav-top.component';
import { NavRightComponent } from './nav-right/nav-right.component';
import { NavFooterComponent } from './nav-footer/nav-footer.component';
import { CoreModule } from './core/core.module';
import { DetailComponent } from './detail/detail.component';
import { AdminComponent } from './admin/admin.component';
import { LinksComponent } from './links/links.component';
import { SysKeysPipe } from './syskeys.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MaterialModule } from './modules/material/material.module';
import { YesNoDialogComponent } from './yes-no-dialog/yes-no-dialog.component';
import { WebDbComponent } from './web-db/panels/web-db.component';
import { WebDbSearchComponent } from './web-db/search/web-db-search.component';
import { DrawGameComponent } from './games/draw-game/draw-game.component';
import { GamesComponent } from './games/games.component';

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        NavTopComponent,
        NavRightComponent,
        NavFooterComponent,
        DetailComponent,
        AdminComponent,
        LoginComponent,
        UserComponent,
        RegisterComponent,
        LinksComponent,
        SysKeysPipe,
        YesNoDialogComponent,
        WebDbComponent,
        WebDbSearchComponent,
        DrawGameComponent,
        GamesComponent
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, { useHash: false }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AppRoutingModule,
        FormsModule,
        CoreModule,
        HttpClientModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatCardModule,
        MatButtonModule,
        BrowserAnimationsModule,
        MaterialModule
    ],
    providers: [AuthService, UserService, UserResolver, AuthGuard, SysKeysPipe],
    bootstrap: [AppComponent]
})
export class AppModule { }
