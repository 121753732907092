import { Component, OnInit, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from '../models/tag';
import { DataService } from '../core/data.service';
import { ActivatedRoute, ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { switchMap, mapTo } from 'rxjs/operators';
import { SysKeysPipe } from '../syskeys.pipe';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.css']
})

@Injectable()
export class NavRightComponent implements OnInit {

  constructor(private dataService: DataService,
    private route: ActivatedRoute,
    public sysKeysPipe: SysKeysPipe
  ) { }

  public allTags: Tag[];
  public hideElement: true;
  public tag: string;
  public techList: string;
  private paramsSub: any;


  ngOnInit() {

    this.dataService.getAllTags()
      .subscribe(
        (data: Tag[]) => {
          this.allTags = data;
        },
        (err: any) => console.log(err),
        () => {
          console.log('All done getting tags.')
          this.techList = this.allTags.map(tag => tag.name).join(', ');
        }
      );
  }

}
