<!-- Footer -->
<footer class="py-1 bg-dark">
  <div class="container">
    <div class="row">
      <div class="col" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a routerLink="/home" routerLinkActive="true">
          <mat-icon>home</mat-icon>
        </a>
      </div>
      <div class="col align-right text-white" data-toggle="collapse" data-target=".navbar-collapse.show">{{currentUser}}</div>
    </div>
  </div>
</footer>
