import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as firebase from 'firebase/app';

@Injectable()
export class UserService {

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth
  ) {
  }


  getCurrentUser(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const currentUser = this.afAuth.onAuthStateChanged(function (user) {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      });
    });
  }

  isLoggedIn(): Promise<Boolean> {
    return new Promise<any>((resolve, reject) => {
      const currentUser = this.afAuth.onAuthStateChanged(function (user) {
        if (user) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  }



  /*
  updateCurrentUser(value):Promise<any> {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      user.updateProfile({
        displayName: value.name,
        photoURL: user.photoURL
      }).then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }
  */

}
