<div class="container h-container">
  <div class="row w-100">
    <div class="w-100">
      <img class="img-fluid" src="assets/images/resources.png">
    </div>
    <div class="w-100">
      <div>
        <div class="card-body w-100">
          <h2 class="card-title">Resources</h2>
          <div class="list-unstyled" *ngFor='let link of allLinks'>
            <a class="list-group-item" href="{{link.url}}">{{link.description}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>