<div class="container h-container" cdkDropListGroup>

    <div class="col-md-12 col-xs-12 header-top">&nbsp;</div>
    <div *ngIf="isLoading" class="col-md-12 col-xs-12 header-top">&nbsp;</div>
    <div *ngIf="isLoading" class="col-md-12 table-container spinner" width="100%">
        <div class="col-md-12 spinner" width="100%">
            <p>&nbsp;</p>
            <mat-spinner class="custom-spinner" mode="indeterminate"></mat-spinner>
            <p>Loading</p>
        </div>
    </div>
    <mat-toolbar *ngIf="!isLoading">
        Web Database
        <div>&nbsp;</div>
        <div>
            <button mat-button (click)="changeTab(item, link)">
                <mat-icon>table_chart</mat-icon> </button>
        </div>
    </mat-toolbar>



    <!-- START -->
    <div class="col-md-12 col-xs-12 header-top">&nbsp;</div>
    <div [hidden]="isLoading">
        <div class="col-md-12">
            <form [formGroup]="searchForm" class="row">
                <mat-form-field class="col-md-12">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" formControlName="clientFilter">
                </mat-form-field>
            </form>
        </div>

        <div class="col-md-12 mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>
                <!-- Position Column -->
                <ng-container matColumnDef="folder">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Folder </th>
                    <td mat-cell *matCellDef="let row"> <a href="{{row.url}}">{{row.folder}}</a></td>
                </ng-container>
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Link </th>
                    <td mat-cell *matCellDef="let row"> <a href="{{row.url}}">{{row.title}}</a></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            &nbsp;
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
    </div>
    <div class="col-md-12 col-xs-12 header-top">&nbsp;</div>
    <div class="col-md-12 col-xs-12 header-top">&nbsp;</div>
    <!-- END -->
</div>