<div class="container h-container">

  <div class="col-md-12 col-xs-12 header-top">&nbsp;</div>

  <mat-toolbar>
    Web Database
    <div>&nbsp;</div>
    <div>

      <button mat-button (click)="changeTab()">
        <mat-icon>search</mat-icon>
      </button>
    </div>

    <div>&nbsp;</div>

  </mat-toolbar>
  <div *ngIf="isLoading" class="col-md-12 col-xs-12 header-top">
    <div class="col-md-12 spinner" width="100%">&nbsp;</div>
    <div class="col-md-12 col-xs-12 header-top">&nbsp;</div>
    <div class="col-md-12 table-container spinner" width="100%">
      <div class="col-md-12 spinner" width="100%">
        <p>&nbsp;</p>
        <mat-spinner class="custom-spinner" mode="indeterminate"></mat-spinner>
        <p>Loading</p>
      </div>
    </div>
    <div class="col-md-12">&nbsp;</div>
    <div class="col-md-12">&nbsp;</div>
  </div>
  <div class="container" *ngIf="!isLoading" cdkDropListGroup>
    <div class="row w-100">
      <div class="col-md-12 col-xs-12 header-top">&nbsp;</div>
      <div class="dragbox-container" class="col-md-4">
        <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="column1" class="dragbox-list"
          (cdkDropListDropped)="drop($event)" id="column1">
          <mat-accordion *ngFor='let folder of column1; let f = index' class="dragbox-box" cdkDrag>
            <mat-expansion-panel>
              <mat-expansion-panel-header class="panel-header">
                <mat-panel-title class="panel-title">
                    {{folder.title}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <div class="col-md-4" cdkDropList [cdkDropListData]="folder.bookmarks" class="dragbox-list"
                (cdkDropListDropped)="drop($event)">
                <div class="list-item" *ngFor='let bookmark of folder.bookmarks; let i = index' cdkDrag>
                  <div class="dragbox-container row">
                    <div id="{{f + '.' + i}}" class="col-md-10 stop-text" (mouseover)="changeStyle($event, f, i)" (mouseout)="changeStyle($event, f, i)">
                    <a id="{{f + '.' + i}}" target="_blank" href={{bookmark.url}} class="panel-link" (mouseover)="changeStyle($event, f, i)" (mouseout)="changeStyle($event, f, i)"> 
                    <!--<button mat-button>-->
                      <img
                        src="http://www.google.com/s2/favicons?domain={{bookmark.url}}" height="16" width="16" border="0">
                      
                      &nbsp; {{bookmark.title}}
                    <!-- </button> -->
                    </a> 
                  </div>
                  <div id="{{f + '.' + i}}" class="col-md-2 menu-icon" (mouseover)="changeStyle($event, f, i)" (mouseout)="changeStyle($event, f, i)">
                       &nbsp;<a id="{{f + '.' + i}}" [ngClass]=" f + '.' + i === color ? 'link-show' : 'link-hide'" (mouseover)="changeStyle($event, f, i)" 
                       (mouseout)="changeStyle($event, f, i)"
                       (click)="editItem(bookmark)"><mat-icon mini-fab class="menu-icon">more_horiz</mat-icon></a> 
                      </div>
                       
                  </div>
                </div>
              </div>
            </ng-template>
            </mat-expansion-panel>
          </mat-accordion>

        </div>
      </div>
      <div class="dragbox-container" class="col-md-4">
        <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="column2" class="dragbox-list"
          (cdkDropListDropped)="drop($event)" id="column2">
          <mat-accordion *ngFor='let folder of column2' class="dragbox-box" cdkDrag>
            <mat-expansion-panel>
              <mat-expansion-panel-header class="panel-header">
                <mat-panel-title class="panel-title">
                  {{folder.title}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <div class="col-md-4" cdkDropList [cdkDropListData]="folder.bookmarks" class="dragbox-list"
                (cdkDropListDropped)="drop($event)">
                <div class="list-item" *ngFor='let bookmark of folder.bookmarks' cdkDrag>
                  <div class="dragbox-container">
                    <a target="_blank" href={{bookmark.url}} class="panel-link"> 
                    <!--<button mat-button>-->
                      <img
                        src="http://www.google.com/s2/favicons?domain={{bookmark.url}}" height="16" width="16"
                        border="0">
                      
                      &nbsp; {{bookmark.title}}
                    <!-- </button> -->
                    </a> 
                       &nbsp;<button mat-icon-button><mat-icon class="edit-icon">more_horiz</mat-icon></button> 
                       
                  </div>
                </div>
              </div>
            </ng-template>
            </mat-expansion-panel>
          </mat-accordion>

        </div>
      </div>
      <div class="dragbox-container" class="col-md-4">
        <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="column3" class="dragbox-list"
          (cdkDropListDropped)="drop($event)" id="column3">
          <mat-accordion *ngFor='let folder of column3' class="dragbox-box" cdkDrag>
            <mat-expansion-panel>
              <mat-expansion-panel-header class="panel-header">
                <mat-panel-title class="panel-title">
                  {{folder.title}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <div class="col-md-4" cdkDropList [cdkDropListData]="folder.bookmarks" class="dragbox-list"
                (cdkDropListDropped)="drop($event)">
                <div class="list-item" *ngFor='let bookmark of folder.bookmarks' cdkDrag>
                  <div class="dragbox-container">
                    <a target="_blank" href={{bookmark.url}} class="panel-link"> 
                    <!--<button mat-button>-->
                      <img
                        src="http://www.google.com/s2/favicons?domain={{bookmark.url}}" height="16" width="16"
                        border="0">
                      
                      &nbsp; {{bookmark.title}}
                    <!-- </button> -->
                    </a> 
                       &nbsp;<button mat-icon-button><mat-icon class="edit-icon">more_horiz</mat-icon></button> 
                       
                  </div>
                </div>
              </div>
            </ng-template>
            </mat-expansion-panel>
          </mat-accordion>

        </div>
      </div>
  <div class="col-md-12">&nbsp;</div>
  <div class="col-md-12">&nbsp;</div>
</div>