import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BookmarkFolder, Bookmark } from '../../models/bookmarks';
import { DataService } from '../../core/data.service';
import { ActivatedRoute, ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { FormControl, Validators, UntypedFormGroup, FormArray, UntypedFormBuilder } from '@angular/forms';
import { switchMap, mapTo } from 'rxjs/operators';
import { SysKeysPipe } from '../../syskeys.pipe';
import { NavigationService } from '../../core/navigation.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgZone } from "@angular/core";

@Component({
  selector: 'app-web-db-search',
  templateUrl: './web-db-search.component.html',
  styleUrls: ['./web-db-search.component.css']
})

@Injectable()

export class WebDbSearchComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dataService: DataService,
    private route: ActivatedRoute,
    public sysKeysPipe: SysKeysPipe,
    public navigationService: NavigationService,
    public fb: UntypedFormBuilder,
    public router: Router,
    private zone: NgZone,
  ) {
    this.navigationService.currentTab = 'webdb';
    this.dataSource = new MatTableDataSource(this.bookmarks);
  }

  public bookmarkFolders: BookmarkFolder[];
  public bookmarks: Bookmark[] = [];
  public column1: BookmarkFolder[] = [];
  public column2: BookmarkFolder[] = [];
  public column3: BookmarkFolder[] = [];
  public hideElement: true;
  public displayedColumns: string[] = ['folder','title'];
  public dataSource: MatTableDataSource<Bookmark> = new MatTableDataSource;
  public clientFilter: string = "";
  public searchForm: UntypedFormGroup;
  public isLoading: boolean = true;
  get f() { return this.searchForm.controls; }


  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataService.getAllBookmarks()
      .then(
        (data: BookmarkFolder[]) => {

          this.bookmarkFolders = this.sysKeysPipe.transform(data)
          this.loadBookmarks();
          this.dataSource = new MatTableDataSource(this.bookmarks);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          this.isLoading = false;

          

        },
        (err: any) => console.log(err)
      );

    this.searchForm = this.fb.group({
      searchFilter: ['All'],
      searchDateFilter: ['7'],
      clientFilter: [''],
    });



  }

  public loadColumns() {

    for (let i = 0; i < this.bookmarkFolders.length; i++) {
      if (this.bookmarkFolders[i].column === 3) {
        this.column3.push(this.bookmarkFolders[i]);
      }
      if (this.bookmarkFolders[i].column === 2) {
        this.column2.push(this.bookmarkFolders[i]);
      }
      if (!this.bookmarkFolders[i].column || this.bookmarkFolders[i].column === 1) {
        this.column1.push(this.bookmarkFolders[i]);
      }
    }


  }

  public changeTab() {
    this.isLoading = true;
    this.zone.run(() => {
      //this.router.navigate(['/webdb']);
      this.router.navigateByUrl('/webdb');
    });


  }

  public loadBookmarks() {

    for (let i = 0; i < this.bookmarkFolders.length; i++) {
      if (this.bookmarkFolders[i].bookmarks) {
        for (let b = 0; b < this.bookmarkFolders[i].bookmarks.length; b++) {
          let bookmark = this.bookmarkFolders[i].bookmarks[b]
          bookmark.folder = this.bookmarkFolders[i].title;
          this.bookmarks.push(bookmark);
        }
      }
    }
  }

  public drop(event: CdkDragDrop<string[]>) {
    let columnId: number = 0;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    switch (event.container.id) {
      case "column1": {
        columnId = 1;
        break;
      }
      case "column2": {
        columnId = 2;
        break;
      }
      case "column3": {
        columnId = 3;
        break;
      }
      default: {
        columnId = 1;
        break;
      }
    }


    for (let i = 0; i < event.container.data.length; i++) {
      let folder: any;
      folder = event.container.data[i];
      folder.column = columnId;
      folder.index = i;
      this.dataService.updateBookMarkFolder(folder);
    }
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    //if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
    //}
  }

}
