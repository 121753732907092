import { Component, OnInit, Injectable, ViewChild, Inject } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { Link } from '../models/link';
import { DataService } from '../core/data.service';
import { ActivatedRoute, ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { SysKeysPipe } from '../syskeys.pipe';
import { Form, NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';
import { NavigationService } from '../core/navigation.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})

@Injectable()
export class DetailComponent implements OnInit {

  @ViewChild(NgForm) createForm: NgForm;
  @ViewChild(NgForm) editForm: NgForm;

  public detailData: Link = new Link;
  public id: any;
  public hideElement: Boolean = true;
  private paramsSub: any;
  public allLinks: Link[];
  public addLink: Link = new Link;
  public tag: string;
  public isLoggedIn: Boolean;
  public selectedForm: Number = -1;
  public editState: Number = -1;
  public isCreating: Boolean = false;
  public yesNoDialogRef: MatDialogRef<YesNoDialogComponent>;
  public prevDesc: string;
  public prevUrl: string;

  constructor(private dataService: DataService,
    private route: ActivatedRoute,
    public sysKeysPipe: SysKeysPipe,
    public authService: AuthService,
    private dialog: MatDialog,
    private navigationService: NavigationService
  ) {
    this.navigationService.currentTab = 'resources';
  }



  ngOnInit() {


    this.authService.isLoggedIn()
    .then(user => {
      this.isLoggedIn = true;
    }, err => {
      this.isLoggedIn = false;
    });



    this.paramsSub = this.route.firstChild.params.subscribe(params => {
      this.id = params['id'];
      console.log(this.id);
    });

    this.dataService.getLinkById(this.id)
      .subscribe(
        (data: Link) => this.detailData = data,
        (err: any) => console.log(err),
        () => {
          console.log('All done getting link.');

          this.dataService.getinksByTag(this.detailData.tag)
            .subscribe(
              (data: Link[]) => this.allLinks = this.sysKeysPipe.transform(data),
              (err: any) => console.log(err),
              () => {
                console.log('All done getting links by tag.');
                this.hideElement = false;
              }
            );
        }
      );
  }

  getDataList() {
    this.dataService.getLinkById(this.id)
      .subscribe(
        (data: Link) => this.detailData = data,
        (err: any) => console.log(err),
        () => {
          console.log('All done getting link.');

          this.dataService.getinksByTag(this.detailData.tag)
            .subscribe(
              (data: Link[]) => this.allLinks = this.sysKeysPipe.transform(data),
              (err: any) => console.log(err),
              () => {
                console.log('All done getting links by tag.');
                this.hideElement = false;
              }
            );
        }
      );
  }

  openYesNoDialog(link: Link) {

    this.yesNoDialogRef = this.dialog.open(YesNoDialogComponent, {
      hasBackdrop: false,
      data: {
        retText: ''
      }
    });

    this.yesNoDialogRef
      .afterClosed()
      .subscribe(name => {
        if (name === 'yes') {
          this.deleteLink(link);
        }
        console.log(name);
      });

  }

  edit(item: number, link: Link) {
    console.log(item);
    this.prevDesc = link.description;
    this.prevUrl = link.url;
    if (this.selectedForm === -1 || this.selectedForm !== item) {
      this.selectedForm = item;
      this.editState = item;
    } else {
      this.selectedForm = -1;
      this.editState = -1;
    }
  }


  deleteLink(link: Link) {
    this.addLink.tag = this.detailData.tag;
    this.dataService.deleteLinkById(link._sysKey)
      .then(res => {
        console.log(`${res} updated successfully.`);
        const idx: number = this.allLinks.findIndex(x => x._sysKey === link._sysKey);
        this.allLinks.splice(idx, 1);
      }, err => {
        console.log('Error');
        console.log(err);
      });
    console.log('Promise');
  }

  create() {
    this.isCreating = !this.isCreating;
  }

  cancel(item: number, link: Link) {
    if (this.selectedForm === -1 || this.selectedForm !== item) {
      this.selectedForm = item;
      this.editState = item;
    } else {
      this.selectedForm = -1;
      this.editState = -1;
    }
    this.editForm.resetForm();
    link.description = this.prevDesc;
    link.url = this.prevUrl;
  }

  cancelAddItem() {
    this.createForm.reset();
    this.createForm.resetForm();
    this.isCreating = !this.isCreating;
  }
  submit(item: number) {
    if (this.selectedForm === -1 || this.selectedForm !== item) {
      this.selectedForm = item;
      this.editState = item;
    } else {
      this.selectedForm = -1;
      this.editState = -1;
    }
  }

  saveChanges(link: Link, item: number): void {
    this.dataService.updateLink(link)
      .then(res => {
        console.log(`${res} updated successfully.`);
        if (this.selectedForm === -1 || this.selectedForm !== item) {
          this.selectedForm = item;
          this.editState = item;
        } else {
          this.selectedForm = -1;
          this.editState = -1;
        }
      }, err => {
        console.log('Error');
        console.log(err);
      });
    console.log('Promise');
  }

  saveChanges2(createForm: NgForm, link: Link, item: number): void {
    this.dataService.updateLink(link)
      .then(res => {
        console.log(`${res} updated successfully.`);
        if (this.selectedForm === -1 || this.selectedForm !== item) {
          this.selectedForm = item;
          this.editState = item;
        } else {
          this.selectedForm = -1;
          this.editState = -1;
        }
      }, err => {
        console.log('Error');
        console.log(err);
      });
    console.log('Promise');
  }
  addItem(addFrm: NgForm): void {
    if (!addFrm.invalid) {
      const lnk: Link = new Link();
      this.addLink.tag = this.detailData.tag;
      this.dataService.addLink(this.addLink)
        .then(res => {
          console.log(`${res} updated successfully.`);
          //lnk.description = this.addLink.description;
          //lnk.url = this.addLink.url;
          //lnk.tag = this.addLink.tag;
          //this.allLinks.push(lnk);
          addFrm.resetForm();
          this.isCreating = !this.isCreating;
          this.getDataList();
        }, err => {
          console.log('Error');
          console.log(err);
        });
      console.log('Promise');
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

}


