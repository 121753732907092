import { Component, OnInit, ViewChild, Injectable, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BookmarkFolder, Bookmark } from '../../models/bookmarks';
import { DataService } from '../../core/data.service';
import { ActivatedRoute, ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { FormControl, Validators, UntypedFormGroup, FormArray, UntypedFormBuilder } from '@angular/forms';
import { switchMap, mapTo } from 'rxjs/operators';
import { SysKeysPipe } from '../../syskeys.pipe';
import { NavigationService } from '../../core/navigation.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';



@Component({

  selector: 'app-web-db',
  templateUrl: './web-db.component.html',
  styleUrls: ['./web-db.component.css']
})
@Injectable()

export class WebDbComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    public sysKeysPipe: SysKeysPipe,
    public navigationService: NavigationService,
    public fb: UntypedFormBuilder,
    public router: Router,
  ) {
    this.navigationService.currentTab = 'webdb';
    this.dataSource = new MatTableDataSource(this.bookmarks);
  }

  public bookmarkFolders: BookmarkFolder[];
  public bookmarks: Bookmark[] = [];
  public column1: BookmarkFolder[] = [];
  public column2: BookmarkFolder[] = [];
  public column3: BookmarkFolder[] = [];
  public hideElement: true;
  public displayedColumns: string[] = ['title'];
  public dataSource: MatTableDataSource<Bookmark> = new MatTableDataSource;
  public clientFilter: string = "";
  public searchForm: UntypedFormGroup;
  public isLoadingObs: Observable<boolean> = of(true)
  public isLoading: boolean = true;
  public color: string = "link-hide";
  
  get f() { return this.searchForm.controls; }
  

  

  ngOnDestroy() {
    console.log("destroy");
  }

  async ngOnInit() {

    this.isLoadingObs.subscribe((result) => {
      this.isLoading = false;
    });


    
    this.dataService.getAllBookmarks()
    .then(
      (data: BookmarkFolder[]) => {
        this.bookmarkFolders = this.sysKeysPipe.transform(data)
        this.loadBookmarks();
        this.loadColumns();
        console.log("Step 1");
        
      })
      .then(()=>{
        console.log("Step 2")
        this.isLoadingObs = of(false);
      });
  }

 
  public toggleLoading(){
    this.isLoading = !this.isLoading;
  }

  public loadColumns() {

    for (let i = 0; i < this.bookmarkFolders.length; i++) {
      if (this.bookmarkFolders[i].column === 3) {
        this.column3.push(this.bookmarkFolders[i]);
      }
      if (this.bookmarkFolders[i].column === 2) {
        this.column2.push(this.bookmarkFolders[i]);
      }
      if (!this.bookmarkFolders[i].column || this.bookmarkFolders[i].column === 1) {
        this.column1.push(this.bookmarkFolders[i]);
      }
    }


  }

  public editItem(bookmark){
    alert(bookmark);
  }
  public changeStyle(event, folder, item) {
    let key = folder.toString() + '.' + item.toString();
    let target = event.srcElement.attributes.id || event.currentTarget.id
    let value = target.nodeValue ? target.nodeValue : target;
    //let value = idAttr.nodeValue ? idAttr.nodeValue : "0";

    if(key === value) {
      //this.color = event.type === 'mouseover' ? 'link-show' : 'link-hide';
      this.color = event.type === 'mouseover' ? key : "";
    } else {
      //this.color = 'link-hide';
      this.color = "";
    }
    
  }

  public changeTab(){
      //this.isLoading = true;
      this.router.navigateByUrl('/webdb/search');
  }

  public loadBookmarks() {

    for (let i = 0; i < this.bookmarkFolders.length; i++) {
      if (this.bookmarkFolders[i].bookmarks) {
        for (let b = 0; b < this.bookmarkFolders[i].bookmarks.length; b++) {
          this.bookmarks.push(this.bookmarkFolders[i].bookmarks[b]);
        }
      }
    }
  }

  public drop(event: CdkDragDrop<string[]>) {
    let columnId: number = 0;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    switch (event.container.id) {
      case "column1": {
        columnId = 1;
        break;
      }
      case "column2": {
        columnId = 2;
        break;
      }
      case "column3": {
        columnId = 3;
        break;
      }
      default: {
        columnId = 1;
        break;
      }
    }


    for (let i = 0; i < event.container.data.length; i++) {
      let folder: any;
      folder = event.container.data[i];
      folder.column = columnId;
      folder.index = i;
      this.dataService.updateBookMarkFolder(folder);
    }
  }


}
