import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserService } from '../core/user.service';


@Injectable()
export class AuthGuard  {

  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser()
      .then(user => {
        if(user) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, err => {
        console.log('Resolve False');
        return resolve(false);
      });
    });
  }
}
