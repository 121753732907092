import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-yes-no-dialog-component',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.css']
})
export class YesNoDialogComponent implements OnInit {

  public dialogTxt: string;

  constructor(
    private dialogRef: MatDialogRef<YesNoDialogComponent>
  ) { }

  ngOnInit() {
  }

  submit() {
    this.dialogRef.close('yes');
  }

  openYesNoDialog(retval: string) {
    this.dialogRef.close(retval);
  }

}
