<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" data-toggle="collapse" data-target=".navbar-collapse.show">
  <div class="container" data-toggle="collapse" data-target=".navbar-collapse.show">
    <a class="navbar-brand" routerLink="/home" routerLinkActive="true">
      <img class="davbar" width="30" height="30" src="assets/images/binary-512.png"> &nbsp; OnezAndZeros</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive" data-toggle="collapse" data-target=".navbar-collapse.show">
      <ul class="navbar-nav ml-auto">
        <li [ngClass]="{ 'active': getTabName('home') }" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="/home" (click)="onClick('home')">Home</a>
        </li>
        <li  [ngClass]="{ 'active': getTabName('resources') }"  class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" >
          <a class="nav-link" routerLink="/links" routerLinkActive="true" (click)="onClick('resources')">Resources</a>
        </li>
        <li  [ngClass]="{ 'active': getTabName('games') }"  class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" >
          <a class="nav-link" routerLink="/games" routerLinkActive="true" (click)="onClick('games')">Games</a>
        </li>
        <li  [ngClass]="{ 'active': getTabName('webdb') }"  class="nav-item" *ngIf="isLoggedIn" data-toggle="collapse" data-target=".navbar-collapse.show" >
          <a class="nav-link" routerLink="/webdb" routerLinkActive="true" (click)="onClick('webdb')">Web Db</a>
        </li>
        <li [ngClass]="{ 'active': getTabName('admin') }" class="nav-item" *ngIf="isLoggedIn" data-toggle="collapse" data-target=".navbar-collapse.show" >
          <a class="nav-link" routerLink="/admin" routerLinkActive="true" (click)="onClick('admin')">Admin</a>
        </li>
        <li [ngClass]="{ 'active': getTabName('login') }" class="nav-item" *ngIf="isLoggedIn" data-toggle="collapse" data-target=".navbar-collapse.show" >
          <a class="nav-link" routerLink="/login" routerLinkActive="true" (click)="onClick('login')">Logout</a>
        </li>
        <li [ngClass]="{ 'active': getTabName('login') }" class="nav-item" *ngIf="!isLoggedIn" data-toggle="collapse" data-target=".navbar-collapse.show" >
          <a class="nav-link" routerLink="/login" routerLinkActive="true" (click)="onClick('login')">Login</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
