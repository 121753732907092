// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCMXB020_0BAv1WodpMy1k841rsPFOOSzE",
    authDomain: "onezandzeros-a1596.firebaseapp.com",
    databaseURL: "https://onezandzeros-a1596.firebaseio.com",
    projectId: "onezandzeros-a1596",
    storageBucket: "onezandzeros-a1596.appspot.com",
    messagingSenderId: "213075950045",
    appId: "1:213075950045:web:192be9ac9a93dd3f98de34"
    // apiKey: 'AIzaSyCMXB020_0BAv1WodpMy1k841rsPFOOSzE',
    // authDomain: 'AIzaSyCMXB020_0BAv1WodpMy1k841rsPFOOSzE.firebaseio.com',
    // databaseURL: 'https://onezandzeros-a1596-c901e.firebaseio.com/',
    // projectId: 'onezandzeros-a1596',
    // storageBucket: 'gs://onezandzeros-a1596.appspot.com',
    // messagingSenderId: '213075950045'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
