<div class="container h-container" *ngIf='!hideElement'>
  <div class="row">
    <div class="col-md-8">
      <h1 class="my-4" [innerHTML]="detailData.title"></h1>
      <div class="card mb-4">
        <img class="card-img-top" [src]="'assets/images/' + detailData.image | lowercase" alt="Card image cap">
        <div class="card-body">
          <p class="card-text" [innerHTML]="detailData.description"></p>
        </div>
        <div class="card-footer text-muted" *ngIf="allLinks.length > 0">
          <h3>Resources</h3>
          <div *ngFor='let link of allLinks; let item=index' class="col-md-12">
            <div class="list-group-item col-md-12 w-100" *ngIf="editState !== item">
              <div class="row">
                <div class="col-md-6">
                  <a *ngIf="editState !== item" href="{{link.url}}">{{link.description}}</a>
                </div>
                <div class="col-md-2">
                  <button mat-button (click)="edit(item, link)" *ngIf="isLoggedIn">
                    <mat-icon>edit</mat-icon> Edit</button>
                </div>
                <div class="col-md-2">
                  <button mat-button (click)="openYesNoDialog(link)" *ngIf="isLoggedIn">
                    <mat-icon>delete</mat-icon> Delete</button>
                </div>
              </div>
            </div>
            <div *ngIf="selectedForm === item" class="w-100 list-group-item">
              <form (ngSubmit)="editForm.form.valid && saveChanges2(editForm, link, item)" #editForm="ngForm">
                <div class="form-group w-100">
                  <label for="d{{item}}">Description</label>
                  <input type="text" name="d{{item}}" value="{{link.description}}" class="form-control" [(ngModel)]="link.description" #d="ngModel"
                    [ngClass]="{ 'is-invalid': editForm.submitted && d.invalid }" required>
                  <div *ngIf="editForm.submitted && d.invalid" class="invalid-feedback">
                    <div *ngIf="d.errors.required">Description is required</div>
                  </div>
                </div>
                <div class="form-group w-100">
                  <label for="u{{item}}">URL</label>
                  <input type="text" name="u{{item}}" class="form-control w-100" [(ngModel)]="link.url" #u="ngModel" [ngClass]="{ 'is-invalid': editForm.submitted && u.invalid }"
                    required>
                  <div *ngIf="editForm.submitted && u.invalid" class="invalid-feedback">
                    <div *ngIf="u.errors.required">URL is required</div>
                  </div>
                </div>
                <div class="form-group btn-group-border">
                  <button type="submit" class="btn btn-outline-primary btn-space btn-sm" *ngIf="editState === item">Submit</button>
                  <button type="button" class="btn btn-outline-primary btn-space btn-sm" (click)="cancel(item, link)" *ngIf="editState === item">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-4" *ngIf="isLoggedIn">
        <div class="card-body">
          <div class="card-text">
            <h3 class="text-muted card-space">Add New Resource
              <button mat-button (click)="create()" *ngIf="editState !== item && isLoggedIn && !isCreating">
                <mat-icon>create</mat-icon> Create</button>
            </h3>
            <form (ngSubmit)="createForm.form.valid && addItem(createForm)" #createForm="ngForm">
              <div *ngIf="isCreating" class="w-100">
                <div class="form-group w-100">
                  <label class="center-block w-100">Description:
                    <input type="text" name="inputDesc" class="form-control w-100" [(ngModel)]="addLink.description" #inputDesc="ngModel" [ngClass]="{ 'is-invalid': createForm.submitted && inputDesc.invalid }"
                      required>
                    <div *ngIf="createForm.submitted && inputDesc.invalid" class="invalid-feedback">
                      <div *ngIf="inputDesc.errors.required">Description is required</div>
                    </div>
                  </label>
                </div>
                <div class="form-group w-100">
                  <label class="center-block w-100">URL:
                    <input type="text" name="inputUrl" class="form-control w-100" [(ngModel)]="addLink.url" #inputUrl="ngModel" [ngClass]="{ 'is-invalid': createForm.submitted && inputUrl.invalid }"
                      required>
                    <div *ngIf="createForm.submitted && inputUrl.invalid" class="invalid-feedback">
                      <div *ngIf="inputUrl.errors.required">URL is required</div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="list-unstyled form-group w-100 btn-group-border">
                <button type="submit" class="btn btn-outline-primary btn-space btn-sm" *ngIf="isCreating">Submit</button>
                <button type="button" class="btn btn-outline-primary btn-space btn-sm" (click)="cancelAddItem()" *ngIf="isCreating">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
