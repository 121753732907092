import { NgModule } from '@angular/core';
import { RouterModule, Routes, Params } from '@angular/router';

import { HomePageComponent } from './home-page/home-page.component';
import { DetailComponent } from './detail/detail.component';
import { AdminComponent } from './admin/admin.component';
import { LinksComponent } from './links/links.component';
import { WebDbComponent } from './web-db/panels/web-db.component';
import { LoginComponent } from './login/login.component';
import { GamesComponent } from './games/games.component';
import { DrawGameComponent } from './games/draw-game/draw-game.component';

import { AuthGuard } from './core/auth.guard';
import { UserResolver } from './user/user.resolver';
import { WebDbSearchComponent } from './web-db/search/web-db-search.component';

export const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomePageComponent },
  {
    path: 'detail', component: DetailComponent,
    children: [
      { path: '', component: DetailComponent },
      { path: '', component: DetailComponent },
      { path: 'item/:id', component: DetailComponent }]
  },
  {
    path: 'links', component: LinksComponent,
    children: [
      { path: '', component: LinksComponent },
      { path: 'tag/:tag', component: LinksComponent }]
  },
  {
    path: 'webdb', component: WebDbComponent ,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: WebDbComponent },
      { path: 'tag/:tag', component: WebDbComponent }]
  },
  { path: 'games', component: GamesComponent,
    children: [
      { path: '', component: DrawGameComponent }]
  },
  {
    path: 'games/draw', component: DrawGameComponent ,
    children: [
      { path: '', component: DrawGameComponent },
      { path: 'action/:action', component: DrawGameComponent }]
  },
  { path: 'webdb/search', component: WebDbSearchComponent},
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: '/home' }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

