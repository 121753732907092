import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { NavigationService } from '../core/navigation.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-nav-top',
  templateUrl: './nav-top.component.html',
  styleUrls: ['./nav-top.component.css']
})

@Injectable()
export class NavTopComponent implements OnInit {
  public currentUser: any;
  public tabName: string = 'home';
  public isLoggedIn: boolean = false;
  public loggedInObservable: Observable<boolean>;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    public navigationService: NavigationService
  ) {
  }

  getTabName(tabName: string) {
    return this.navigationService.currentTab === tabName ? true : false;
  }
  onClick(tabName: string) {
    this.navigationService.currentTab = tabName;
  }
  ngOnInit() {

    this.loggedInObservable = Observable.create(
      observer => this.userService.afAuth
        .onAuthStateChanged((user) => {
          this.isLoggedIn = !!user;
          observer.next(!!user)
        })
    ).subscribe();

    this.userService.isLoggedIn()
      .then(user => {
        if (user) {
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      }, err => {
        this.isLoggedIn = false;
      });

  }

}