import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Link } from '../models/link';
import { DataService } from '../core/data.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NavigationService } from '../core/navigation.service';



@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  allTopics: Link[];
  hideElement: Boolean = true;

  constructor(private dataService: DataService,
    public navigationService: NavigationService) {
      this.navigationService.currentTab = 'home';
    }
  ngOnInit() {
    this.dataService.getAllTopics()
      .subscribe(
        (data: Link[]) => this.allTopics = data,
        (err: any) => console.log(err),
        () => {
          console.log('All done getting links.' + this.allTopics);
          this.hideElement = false;
        }
      );
  }

}
