import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserService } from '../core/user.service';
import * as firebase from 'firebase/app';
import { getAuth } from 'firebase/auth';

@Injectable()
export class AuthService {

  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService
  ) {



  }

  // doFacebookLogin() {
  //   return new Promise<any>((resolve, reject) => {
  //     const provider = new firebase.auth.FacebookAuthProvider();
  //     this.afAuth
  //       .signInWithPopup(provider)
  //       .then(res => {
  //         resolve(res);
  //       }, err => {
  //         console.log(err);
  //         reject(err);
  //       });
  //   });
  // }

  // doTwitterLogin() {
  //   return new Promise<any>((resolve, reject) => {
  //     const provider = new firebase.auth.TwitterAuthProvider();
  //     this.afAuth
  //       .signInWithPopup(provider)
  //       .then(res => {
  //         resolve(res);
  //       }, err => {
  //         console.log(err);
  //         reject(err);
  //       });
  //   });
  // }

  // doGoogleLogin() {
  //   return new Promise<any>((resolve, reject) => {
  //     const auth = getAuth();
  //     const provider = new auth.g
  //     provider.addScope('profile');
  //     provider.addScope('email');
  //     this.afAuth
  //       .signInWithPopup(provider)
  //       .then(res => {
  //         resolve(res);
  //       }, err => {
  //         console.log(err);
  //         reject(err);
  //       });
  //   });
  // }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(value.email, value.password)
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(value.email, value.password)
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }

  doLogout() {

    this.afAuth.signOut();
    /*
    return this.userService.isLoggedIn()
      .then(res => {
        this.afAuth.signOut();
      }, err => {
        console.log(err);
      });
    */
  }

  isLoggedIn(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser()
        .then(user => {
          if (user) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, err => {
          console.log('Resolve False');
          return resolve(false);
        });
    });

  }



}
